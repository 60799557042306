import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card, Title, TextBlock, Button,
} from '@lxlabs/change-ad-components';

const Style = styled(Card)`
  margin: 0px !important;
  width: ${({ fullWidth }) => (fullWidth) && '100%'};
  display: ${({ fullWidth }) => (!fullWidth) && 'inline-block'};
  float: ${({ fullWidth }) => (!fullWidth) && 'left'};
`;

const ManagingCard = ({
  title, text, buttonText, link, fullWidth, backgroundColor,
}) => (
  <Style
    alignment="left"
    backgroundColor={backgroundColor}
    fullWidth={fullWidth}
    padding={{
      top: 'large',
      bottom: 'large',
      left: 'large',
      right: 'large',
    }}
  >
    {title && <Title size="small" spacing={{ bottom: 'small' }}>{title}</Title>}
    {typeof text === 'string' ? (
      <TextBlock spacing={{ top: 'small', bottom: buttonText && 'medium' }}>{text}</TextBlock>
    ) : (
      text
    )}

    {buttonText && <Button text={buttonText} href={link} />}
  </Style>
);

ManagingCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  fullWidth: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

ManagingCard.defaultProps = {
  title: '',
  buttonText: '',
  link: '',
  fullWidth: false,
  backgroundColor: 'secondary-30',
};

export default ManagingCard;
